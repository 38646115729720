<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3">
    <v-card class="py-3">
      <div class="d-flex align-center justify-space-between px-3">
        <span class="font-12px text-888">{{ today }}</span>
        <v-btn
          text
          :to="{ name: 'cashbookCreate' }"
          class="font-weight-400 primary--text font-14px px-0"
        >
          ＋ 新規作成
        </v-btn>
      </div>

      <v-row justify="center" class="mt-3">
        <v-col cols="6" class="py-0">
          <v-select
            solo
            dense
            placeholder="2021年"
            v-model="year"
            background-color="#EAEDF0"
            class="cashbook-select"
            :items="years"
            item-value="value"
            item-text="text"
            @change="getDataFromApi"
          >
            <template v-slot:selection="{ item }">
              <span class="d-flex position-absolute justify-center full-width">
                {{ item.text }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10" class="py-0">
          <v-slide-group
            show-arrows
            mandatory
            v-model="month"
            @change="getDataFromApi"
          >
            <v-slide-item
              v-for="month in months"
              :key="month"
              v-slot="{ active, toggle }"
              :value="month"
              active-class="month-active"
            >
              <v-btn
                class="fw-400 px-0"
                min-width="46px"
                :input-value="active"
                active-class="font-weight-bold month-active"
                text
                @click="toggle"
              >
                {{ month }}月
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>

      <div
        class="mx-4 mt-6 px-3 py-3 d-flex align-center justify-space-around fw-500 dr-form-balance-text"
      >
        <span class="font-14px">現在の残高</span>
        <!--<span class="font-36px">{{ getCashbookBalance.length?getCashbookBalance[0].cumulative_balance:0 | formatMoney }}</span>-->
        <span class="font-36px">{{
          parseInt(getCashbookBalance) | formatMoney
        }}</span>
      </div>

      <p class="mx-4 my-4 font-12px">
        <span class="fw-700">{{
          `${getCashbookPaginate.records_from}~${getCashbookPaginate.records_to}件 / ${getCashbookPaginate.records_total}件`
        }}</span>
        の現金入出金データを表示しています。
      </p>
      <DataTable
        :headers="tableHeaders"
        :items="loading ? [] : cashbooks"
        :total-records="
          getCashbookPaginate ? getCashbookPaginate.records_total : 0
        "
        :number-of-pages="
          getCashbookPaginate ? getCashbookPaginate.total_pages : 0
        "
        :items-per-page="itemsPerPage"
        item-class="cashbook-col-bg-color"
        class="text-center cashbook-table"
        mobile-breakpoint="275"
        @update:options="updateTable($event)"
      >
        <template v-slot:item.deposit="{ item }">
          <span
            class="deposit"
            :class="Number.isInteger(item.deposit) ? 'font-weight-bold' : ''"
          >
            {{ item.deposit | formatNumber }}
          </span>
        </template>
        <template v-slot:item.withdrawal="{ item }">
          <span class="font-weight-bold withdrawal">
            {{ item.withdrawal | formatNumber }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            class="mr-2 icon-opacity"
            text
            icon
            plain
            :ripple="false"
            :to="{
              name: 'cashbookEdit',
              params: { id: item.id }
            }"
          >
            <v-icon>
              $edit
            </v-icon>
          </v-btn>
        </template>
        <template slot="no-data">表示する項目はありません。</template>
      </DataTable>
    </v-card>
  </v-container>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "cashbookList",
  components: { DataTable },
  filters: {
    formatNumber(value) {
      if (!Number.isInteger(value)) return value;
      return Number(value).toLocaleString("ja-JP");
    },
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters([
      "getShops",
      "getFirstShopObject",
      "getCashbooks",
      "getCashbookBalance",
      "getCashbookPaginate"
    ]),
    currentShop() {
      return this.getFirstShopObject;
    },
    cashbooks() {
      return this.getCashbooks.map(cashbook => {
        return {
          id: cashbook.id,
          accrual_date: dayjs(cashbook.accrual_date).format("MM/DD dd"),
          deposit:
            cashbook.withdrawal_or_deposit === 2
              ? cashbook.purchase_amount
              : cashbook.partner?.display_name,
          withdrawal:
            cashbook.withdrawal_or_deposit === 1 ? cashbook.purchase_amount : ""
        };
      });
    },
    months() {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push(i);
      }
      if (this.currentShop.start_of_fiscal_year) {
        // TODO: Shift months according to the start of the fiscal year
      }
      return months;
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    }
  },
  data() {
    return {
      loading: false,
      table1page: 1,
      itemsPerPage: 10,
      pageLoading: false,
      table1pageCount: 0,
      tableHeaders: [
        {
          text: "取引日",
          align: "center",
          value: "accrual_date",
          sortable: true,
          width: 80
        },
        {
          text: "入金",
          align: "center",
          value: "deposit",
          sortable: false,
          width: 100
        },
        {
          text: "出金",
          align: "center",
          value: "withdrawal",
          sortable: false,
          width: 100
        },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
          width: 40
        }
      ],
      years: [],
      year: dayjs().format("YYYY"),
      month: parseInt(dayjs().format("MM")),
      pageStart: "",
      pageStop: "",
      initialLoad: true
    };
  },
  created() {
    let today = dayjs();
    let begin = today.subtract(1, "year");
    let finish = today.add(3, "year");

    while (begin.isSameOrBefore(finish)) {
      this.years.push({
        text: begin.format("YYYY年"),
        value: begin.format("YYYY")
      });
      begin = begin.add(1, "year");
    }

    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  methods: {
    updateTable(e) {
      if (!this.initialLoad) {
        this.getDataFromApi(e);
      }
    },
    getDataFromApi(e = undefined) {
      this.loading = true;
      this.shop_id = this.currentShop?.id;
      let param = {
        id: this.shop_id, //Shop Id
        param: {
          year: this.year,
          month: this.month,
          page: 1,
          paginate: 10
        }
      };

      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        param.param.orderBy = e.sortDesc[0] ? "desc" : "asc";
        param.param.sort = e.sortBy[0];
      } else {
        param.param.sort = "accrual_date";
        param.param.orderBy = "desc";
      }

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        param.param.page = e.page;
        param.param.paginate = e.itemsPerPage;
      }

      // this.shop_id = this.currentShop?.id;
      // let param = {
      //   id: this.shop_id, //Shop Id
      //   param: {
      //     year: this.year,
      //     month: this.month
      //   }
      // };
      this.$store.dispatch("CASHBOOK_GET_ALL", param).finally(() => {
        this.loading = false;
        this.initialLoad = false;
      });
    },
    colorCol() {
      return "cashbook-col-bg-color";
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
